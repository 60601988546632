import React, { useState, useEffect } from 'react';
import './InteractiveCard.css'; // Make sure to update this CSS file for modal styles
import animatedFlowers from '../animate/flowers.png'
import { enableScroll, disableScroll } from './ScrollControl'; // Import utility functions
const InteractiveCard = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    
    const giftBox = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="giftBox">
            <path d="M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z"/>
        </svg>
    );

    const [animationPhase, setAnimationPhase] = useState(0); // 0: idle, 1: animate flowers, 2: show message
    useEffect(() => {
        if (isOpen) {
            enableScroll();
        } else {
            disableScroll();
        }

        // Clean-up function to ensure scrolling is enabled when the component unmounts
        return () => enableScroll();
    }, [isOpen]);
    // Trigger animation flow
    const triggerFlowersAnimation = () => {
        if (animationPhase !== 0) return; // Prevent triggering if already in process
        setAnimationPhase(1); // Start with flower animation
    };

    useEffect(() => {
        if (animationPhase === 1) {
            // After flowers animation, proceed to show message
            const timeoutId = setTimeout(() => setAnimationPhase(2), 2500); // Match this duration to your flowers animation
            return () => clearTimeout(timeoutId);
        } else if (animationPhase === 2) {
            // After message is shown, reset to idle state
            const timeoutId = setTimeout(() => setAnimationPhase(0), 2000); // Message display duration
            return () => clearTimeout(timeoutId);
        }
    }, [animationPhase]);
    const letter = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/>
        </svg>
    );

    const closeLetter = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M64 208.1L256 65.9 448 208.1v47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5V208.1zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V208.1c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z"/>
        </svg>

    );
    const openLetter = () => {
        setIsOpen(true);
        props.onToggleGalleryPause();
    };

    const closeModal = () => {
        setIsOpen(false);
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        props.onToggleGalleryPause();
    };
    const Modal = ({ onClose }) => (
        <div className="modal">
            <div className="modal-content">
                <div className="letter">
                    <strong>Dear Lauren,</strong>
                    <p>Happy Valentine's Day! I wish I had more pictures but that just means we will have to take more lol. You truly mean more to me than I am capable of describing with words.
                        When I am with you I feel a sense of peace that I have never felt before. Every time I look at you I can't help but smile you're incredibly beautiful inside and out and I want to give
                        you all that you deserve. I remember one time we were sitting in Herbies Burgers and you asked me why you were a keeper. I believe I responded with something dumb like because you're "so cool".
                        What I was trying to say was because I enjoy every second I spend with you, we could both be locked up though I know it wouldn't be ideal but the mere reality of having you there by my side
                        is all I would need for the day to be good. When I look into your eyes I see the most beautiful soul I have ever laid eyes upon. Your mere essence is so positive, loving, nurturing, and awe-inspiring.
                        If I can even be half the person that I believe you are then I know I am on the right track. You truly make me a better man and I am so thankful for the time that we have spent together. I know that
                        words are words at the end of the day so I am going to do everything in my power to show you just how much you mean to my heart and my soul through my daily actions! Lastly, I would like to share a quote which
                        echoes some of my feelings, "I would recognize you in total darkness, were you mute and I deaf. I would recognize you in another lifetime entirely, in different bodies, 
                        in different times. And I would love you in all of this, until the very last star in the sky burnt out into oblivion."
                    </p>
                    <p>Love,<br />Yotham</p>
                    <button className="close" onClick={onClose}>{closeLetter}</button>
                </div>
            </div>
        </div>
    );
    

    return (
        <div className = "interactive-card-container">
            <div className="interactive-card">
                < button className="letterButton" onClick={openLetter}>
                    {letter}
                </button>
                {isOpen && <Modal onClose={closeModal}/>}
            </div>
            <button className="letterButton" onClick={triggerFlowersAnimation}>
                {giftBox}
            </button>
            {animationPhase >= 1 && (
                <div className="flowers-animation">
                    <img className = "flowers" src={animatedFlowers} alt="Animated Flowers" draggable="false" />
                </div>
            )}

            {/* Conditionally render message based on animationPhase */}
            {animationPhase === 2 && <div className="message">For you pookie</div>}

        </div>
    );
}

export default InteractiveCard;
