// ScrollControl.js
export const disableScroll = () => {
    document.body.style.overflow = 'hidden';
    // Add an event listener with a more specific handler
    document.addEventListener('touchmove', handleTouchMove, { passive: false });
};

export const enableScroll = () => {
    document.body.style.overflow = '';
    // Remove the specific handler
    document.removeEventListener('touchmove', handleTouchMove, { passive: false });
};
  
  function handleTouchMove(e) {
    // Check if the target of the touch move is an input range (or other elements you want to allow)
    // You might need to adjust the selector based on your markup
    if (e.target.nodeName === 'INPUT' && e.target.type === 'range') {
        return; // Allow the default action (i.e., let the user drag the slider)
    }
    // For other elements, prevent the default action (i.e., scrolling)
    e.preventDefault();
}