import React, { useState } from 'react';
import './App.css';
import Background from './components/background';
import PhotoGallery from './components/PhotoGallery';
import InteractiveCard from './components/InteractiveCard';
import AudioPlayer from './components/AudioPlayer';

import loversRock from './songs/Lovers_Rock.mp3';  // Adjust the path as necessary
import fiveofive from './songs/505.mp3';  // Adjust the path as necessary
import cigOutWindow from './songs/CigOutWindow.mp3';  // Adjust the path as necessary
import DarkBeach from './songs/dark_beach.mp3';  // Adjust the path as necessary
import mrsMagic from './songs/Strawberry_Guy_Mrs_Magic.mp3'
import iwdiya from './songs/iwdiya.mp3'
import pj from './songs/Depeche_Mode_PJ.mp3'
import africa from './songs/toto_africa.mp3'
import jtwya from './songs/jtwya.mp3'
import bf from './songs/bf.mp3'
import tiab from './songs/tiab.mp3'
import sd from './songs/stolenDance.mp3'
import ilyinasong from './songs/ilyinasong.mp3'
import iwby from './songs/iwby.mp3'
import htd from './songs/htd.mp3'
import aty from './songs/aty.mp3'
import perfect from './songs/perfect.mp3'
import unify from './songs/unify.mp3'
import chfil from './songs/chfil.mp3'
import jttou from './songs/jttou.mp3'
import mm from './songs/mm.mp3'
import sg from './songs/sg.mp3'
import ytro from './songs/ytro.mp3'
import soms from './songs/soms.mp3'

import loversRockCover from './songs/loversRock.PNG';  // Adjust the path as necessary
import fiveofiveCover from './songs/505.jpg';  // Adjust the path as necessary
import cigOutWindowCover from './songs/CigOutWindow.jpg';  // Adjust the path as necessary
import DarkBeachCover from './songs/dark_beach.jpg';  // Adjust the path as necessary
import mrsMagicCover from './songs/mrsmagic.jpg'
import iwdiyaCover from './songs/iwdiya.jpg'
import pjCover from './songs/pj.jpg'
import africaCover from './songs/africa.jpg'
import jtwyaCover from './songs/jtwya.jpg'
import bfCover from './songs/bf.jpg'
import tiabCover from './songs/tiab.jpg'
import sdCover from './songs/stolenDance.jpg'
import ilyinasongCover from './songs/ilyinasong.jpg'
import iwbyCover from './songs/iwby.jpg'
import htdCover from './songs/htd.jpg'
import atyCover from './songs/aty.jpg'
import perfectCover from './songs/perfect.jpg'
import unifyCover from './songs/unify.jpg'
import chfilCover from './songs/chfil.jpg'
import jttouCover from './songs/jttou.jpg'
import mmCover from './songs/mm.jpg'
import sgCover from './songs/sg.jpg'
import ytroCover from './songs/ytro.jpg'
import somsCover from './songs/soms.jpg'

const mySongs = [
  { id: "1", name: "Lovers Rock", artist: "TV Girl", url: loversRock, imageUrl: loversRockCover },
  { id: "2", name: "505", artist: "Arctic Monkeys", url: fiveofive, imageUrl: fiveofiveCover },
  { id: "3", name: "Cigarettes out the Window", artist: "TV Girl", url: cigOutWindow, imageUrl: cigOutWindowCover },
  { id: "4", name: "Dark Beach", artist: "Pastel Ghost", url: DarkBeach, imageUrl: DarkBeachCover },
  { id: "5", name: "Mrs Magic", artist: "Strawberry Guy", url: mrsMagic, imageUrl: mrsMagicCover },
  { id: "6", name: "i want to die in your arms", artist: "EKKSTACY", url: iwdiya, imageUrl: iwdiyaCover },
  { id: "7", name: "Personal Jesus", artist: "Depeche Mode", url: pj, imageUrl: pjCover },
  { id: "8", name: "Africa", artist: "TOTO", url: africa, imageUrl: africaCover },
  { id: "9", name: "Just The Way You Are", artist: "Bruno Mars", url: jtwya, imageUrl: jtwyaCover },
  { id: "10", name: "Beautiful Soul", artist: "Jesse McCartney", url: bf, imageUrl: bfCover },
  { id: "11", name: "Time In A Bottle", artist: "Jim Croce", url: tiab, imageUrl: tiabCover },
  { id: "12", name: "Stolen Dance", artist: "Milky Chance", url: sd, imageUrl: sdCover },
  { id: "13", name: ["I'll Have To Say", " ","I Love You In A Song"], artist: "Jim Croce", url: ilyinasong, imageUrl: ilyinasongCover },
  { id: "14", name: "I Wanna Be Yours", artist: "Arctic Monkeys", url: iwby, imageUrl: iwbyCover },
  { id: "15", name: "Hey There Delilah", artist: "Plain White T's", url: htd, imageUrl: htdCover },
  { id: "16", name: "A Thousand Years", artist: "Christina Perri", url: aty, imageUrl: atyCover },
  { id: "17", name: "Perfect", artist: "Ed Sheeran", url: perfect, imageUrl: perfectCover },
  { id: "18", name: "Until I Found You", artist: "Stephen Sanchez", url: unify, imageUrl: unifyCover },
  { id: "19", name: "Can't Help Falling In Love", artist: "Elvis Presley", url: chfil, imageUrl: chfilCover },
  { id: "20", name: "Just The Two Of Us", artist: "Grover Washington, Jr.", url: jttou, imageUrl: jttouCover },
  { id: "21", name: "Metamodernity", artist: "Vansire", url: mm, imageUrl: mmCover },
  { id: "22", name: "Stargirl Interlude", artist: "The Weeknd - Lana Del Ray", url: sg, imageUrl: sgCover },
  { id: "23", name: "You Are The Right One", artist: "Sports", url: ytro, imageUrl: ytroCover },
  { id: "24", name: "Somethin' Stupid", artist: "Frank Sinatra", url: soms, imageUrl: somsCover}
];

function App() {
  const [isGalleryPaused, setIsGalleryPaused] = useState(false);
  const [isGalleryLoaded, setIsGalleryLoaded] = useState(false); // New state to track loading

  const toggleGalleryPause = () => {
    setIsGalleryPaused(!isGalleryPaused);
  };

  const handleGalleryLoaded = () => {
    setIsGalleryLoaded(true); // Call this when the gallery has loaded
  };

  return (
    <div className="App">
      <AudioPlayer songs={mySongs} />
      <Background>
        <PhotoGallery isPaused={isGalleryPaused} onLoaded={handleGalleryLoaded} />
        {isGalleryLoaded && <InteractiveCard onToggleGalleryPause={toggleGalleryPause} />}
      </Background>
    </div>
  );
}

export default App;
