import React from 'react';
import './Background.css';
import flowerImage from '../images/flower2.jpg'; // Ensure the path is correct

const Background = ({ children }) => {
    return (
        <div className="background" style={{ backgroundImage: `url(${flowerImage})` }}>
            {children}
        </div>
    );
}

export default Background;
