// src/components/PhotoGallery.js
import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick';
import './PhotoGallery.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import photo001 from '../images/photo001.jpg'
import photo002 from '../images/photo002.jpg'
import photo003 from '../images/photo003.jpg'
import photo1 from '../images/photo1.jpg';
import photo2 from '../images/photo2.PNG';
import photo3 from '../images/photo3.jpg';
import photo4 from '../images/photo4.PNG';
import photo5 from '../images/photo5.jpg';
import photo6 from '../images/photo6.jpg';
import photo7 from '../images/photo7.PNG';
import photo8 from '../images/photo8.jpg';
import photo9 from '../images/photo9.jpg';
import photo10 from '../images/photo10.jpg';
import photo11 from '../images/photo11.jpg';
import photo12 from '../images/photo12.jpg';
import photo13 from '../images/photo13.jpg';
import photo14 from '../images/photo14.jpg';
import photo15 from '../images/photo15.jpg';
import photo16 from '../images/photo16.jpg';
import photo17 from '../images/photo17.jpg';
import photo18 from '../images/photo18.jpg';
import photo19 from '../images/photo19.jpg';
import photo20 from '../images/photo20.jpg';
import photo21 from '../images/photo21.jpg';
import photo22 from '../images/photo22.jpg';
import photo23 from '../images/photo23.jpg';
import photo24 from '../images/photo24.jpg';
import photo25 from '../images/photo25.jpg';
import photo26 from '../images/photo26.jpg';
import photo27 from '../images/photo27.jpg';
import photo28 from '../images/photo28.jpg';
//import { disableScroll } from './ScrollControl';

const photos = [
    { src: photo001, alt: '001 Description' },
    { src: photo002, alt: '002 Description' },
    { src: photo003, alt: '003 Description' },
    { src: photo1, alt: '1 Description' },
    { src: photo2, alt: '2 Description' },
    { src: photo3, alt: '3 Description' },
    { src: photo4, alt: '4 Description' },
    { src: photo5, alt: '5 Description' },
    { src: photo6, alt: '6 Description' },
    { src: photo7, alt: '7 Description' },
    { src: photo8, alt: '8 Description' },
    { src: photo9, alt: '9 Description' },
    { src: photo10, alt: '10 Description' },
    { src: photo11, alt: '11 Description' },
    { src: photo12, alt: '12 Description' },
    { src: photo13, alt: '13 Description' },
    { src: photo14, alt: '14 Description' },
    { src: photo15, alt: '15 Description' },
    { src: photo16, alt: '16 Description' },
    { src: photo17, alt: '17 Description' },
    { src: photo18, alt: '18 Description' },
    { src: photo19, alt: '19 Description' },
    { src: photo20, alt: '20 Description' },
    { src: photo21, alt: '21 Description' },
    { src: photo22, alt: '22 Description' },
    { src: photo23, alt: '23 Description' },
    { src: photo24, alt: '24 Description' },
    { src: photo25, alt: '25 Description' },
    { src: photo26, alt: '26 Description' },
    { src: photo27, alt: '27 Description' },
    { src: photo28, alt: '28 Description' }
];


const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
};

const PhotoGallery = ({ isPaused, onLoaded }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [shuffledPhotos, setShuffledPhotos] = useState([]);
    const slider = useRef(null);
    useEffect(() => {
        onLoaded(); // Call this to indicate the gallery has loaded
      }, [onLoaded]);

    useEffect(() => {
        // Shuffle photos on component mount
        setShuffledPhotos(shuffleArray([...photos]));
    }, []);
    const handleWheel = (e) => {
        if (e.deltaY < 0) {
            // Scrolling up
            slider.current.slickPrev();
        } else {
            // Scrolling down
            slider.current.slickNext();
        }
    };
    const settings = {
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: !isPaused,
        autoplaySpeed: 3000, // Autoplay speed in milliseconds,
        arrows: false, // Hide the arrows,
        afterChange: current => setCurrentSlide(current)
    };
    useEffect(() => {
        if (isPaused) {
            // If paused, stop autoplay and remember current slide
            slider.current.slickPause();
        } else {
            // If not paused, start autoplay and go to the remembered slide
            slider.current.slickPlay();
            slider.current.slickGoTo(currentSlide);
        }
    }, [isPaused, currentSlide]);
    return (
        <div className="slider-container">
            <Slider ref={slider} {...settings} onWheel={handleWheel}>
                {shuffledPhotos.map((photo, index) => (
                    <div key={index} className="image-container">
                        <img src={photo.src} className="gallery-image" alt={photo.alt} />
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default PhotoGallery;
