import React, { useState, useEffect, useRef, useMemo } from 'react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { enableScroll} from './ScrollControl'; // Import utility functions
import './AudioPlayer.css';
const shuffleArray = (array) => {
    for (let currentIndex = array.length - 1; currentIndex > 0; currentIndex--) {
        const randomIndex = Math.floor(Math.random() * (currentIndex + 1));
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
}

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  
 

  const Queue = ({currentSong, queueSongs, updateQueue }) => {
    
    const onDragEnd = (result) => {
        console.log('Drag result:', result);
      
        if (!result.destination) {
          console.log('No destination found.');
          return;
        }
      
        const newSongs = reorder(
          queueSongs,
          result.source.index,
          result.destination.index
        );
      
        console.log('New songs after reorder:', newSongs);
        updateQueue(newSongs);
      };
  
    return (
        <DragDropContext onDragEnd={onDragEnd}>
        <div className = "wholeQueue">
          <div className="current-song">
            <div className="queue-itemCur">
              <span className='qSongTitle'>{currentSong.artist}</span> - <span className='qSongName'>{currentSong.name}</span>
            </div>
          </div>
          <Droppable droppableId="droppable-queue">
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="queue">
                {queueSongs.map((song, index) => {
                    // Skip the current song
                    if (song.id === currentSong.id) return null;
                    
                    return (
                    <Draggable key={song.id.toString()} draggableId={song.id.toString()} index={index}>
                        {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="queue-item"
                        >
                            <span className='qSongTitle'>{song.artist}</span> - <span className='qSongName'>{song.name}</span>
                        </div>
                        )}
                    </Draggable>
                    );
                })}
                {provided.placeholder}
                </div>
            )}
            </Droppable>
        </div>
        </DragDropContext>
      );
    };

const AudioPlayer = ({ songs }) => {
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false); // Start paused
    const [progress, setProgress] = useState(0);
    const [isShuffle, setIsShuffle] = useState(false); // Shuffle state
    const [shuffledSongs, setShuffledSongs] = useState([]); // Shuffled songs array
    const audioRef = useRef(null);
    const [volume, setVolume] = useState(0.2); // Initial volume state
    const [currentTime, setCurrentTime] = useState(0); // New state for current time
    const [isMuted, setIsMuted] = useState(false);
    const [previousVolume, setPreviousVolume] = useState(0.2);
    const [queue, setQueue] = useState(songs);
    const [isQueueVisible, setIsQueueVisible] = useState(false);
    //const [originalQueue, setOriginalQueue] = useState([...songs]); // Step 1: Maintain a copy of the original queue
    const [playedSongs, setPlayedSongs] = useState([]);
    const [originalShuffledSongs, setOriginalShuffledSongs] = useState([]);

    const toggleQueue = () => {
        setIsQueueVisible(!isQueueVisible);
    };

    
    const toggleMute = () => {
        if (!isMuted) {
            setPreviousVolume(audioRef.current.volume);
            audioRef.current.volume = 0;
            setIsMuted(true);
        } else {
            audioRef.current.volume = previousVolume;
            setIsMuted(false);
        }
    };
    // Modify the onVolumeChange function
    const onVolumeChange = (e) => {
        const newVolume = parseFloat(e.target.value);
        audioRef.current.volume = newVolume;
        setVolume(newVolume);
    
        if (newVolume === 0) {
            setIsMuted(true);
        } else {
            setIsMuted(false);
            setPreviousVolume(newVolume);
        }
    };
    
    const playAudio = () => {
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
            playPromise.then(() => {
                // Automatic playback started
            }).catch(error => {
                console.error("Playback failed", error);
                // Handle playback failure
            });
        }
    };

    const pauseAudio = () => {
        audioRef.current.pause();
    };
    
    useEffect(() => {
        // Shuffle songs when shuffle is activated
        if (isShuffle) {
            const shuffled = shuffleArray([...songs]);
            setShuffledSongs(shuffled);
        }
    }, [isShuffle, songs]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = 0.2; // Sets the volume to 20%
        }
    }, [currentSongIndex]);

    const updateProgress = (e) => {
        enableScroll()
        const audio = audioRef.current;
        if (audio && isFinite(audio.duration)) {
            const playTime = (parseFloat(e.target.value) / 100) * audio.duration;
            audio.currentTime = playTime;
        }
    };
    
    useEffect(() => {
        const audio = audioRef.current;

        const updateTime = () => {
            const current = audio.currentTime;
            setCurrentTime(current);
            const newProgress = (current / audio.duration) * 100;
            setProgress(isFinite(newProgress) ? newProgress : 0); // Update to make sure progress is a number
        };

        audio.addEventListener('timeupdate', updateTime);

        return () => {
            audio.removeEventListener('timeupdate', updateTime);
        };
    }, [currentSongIndex, isPlaying, songs]);
    
    

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const playNextSong = () => {
        let nextIndex = currentSongIndex;
        
        // Adjust how the current song is added to playedSongs based on shuffle mode
        const currentSongToAdd = isShuffle ? shuffledSongs[currentSongIndex] : queue[currentSongIndex];
        setPlayedSongs(prevPlayedSongs => [...prevPlayedSongs, currentSongToAdd]);
    
        if (isShuffle) {
            if (shuffledSongs.length > 1) {
                // Move to the next song in shuffle mode
                nextIndex = (currentSongIndex + 1) % shuffledSongs.length;
            } else {
                // Reset shuffledSongs if it was the last song
                setShuffledSongs([...originalShuffledSongs]);
                nextIndex = 0;
            }
        } else {
            // Move to the next song in the normal queue
            nextIndex = (currentSongIndex + 1) % queue.length;
        }
    
        setCurrentSongIndex(nextIndex);
        setIsPlaying(true);
        //console.log(playedSongs)
    };
    


    const playPreviousSong = () => {
        if (playedSongs.length > 0) {
            // Get the last song from the played songs history
            const previousSong = playedSongs[playedSongs.length - 1];
            console.log(playedSongs)
            // Find the previous song in the current queue or shuffledSongs
            const previousIndex = isShuffle
                ? shuffledSongs.findIndex(song => song.id === previousSong.id)
                : queue.findIndex(song => song.id === previousSong.id);
    
            if (previousIndex === -1) {
                // If the song is not in the current queue, re-add it
                if (isShuffle) {
                    shuffledSongs.unshift(previousSong);
                    setShuffledSongs([...shuffledSongs]);
                } else {
                    queue.unshift(previousSong);
                    setQueue([...queue]);
                }
                setCurrentSongIndex(0);
            } else {
                // If the song is in the queue, just switch to it
                setCurrentSongIndex(previousIndex);
            }
    
            // Remove the last song from the playedSongs array
            const newPlayedSongs = playedSongs.slice(0, -1);
            setPlayedSongs(newPlayedSongs);
    
            setIsPlaying(true);
        } else {
            // If there are no previously played songs, handle as needed (e.g., do nothing or reset to the start)
        }
    };
    const toggleShuffle = () => {
        setIsShuffle(!isShuffle);
        setPlayedSongs([])
        if (!isShuffle) {
            // Switching to shuffle mode
            const shuffled = shuffleArray([...queue]);
            setShuffledSongs(shuffled);
            setOriginalShuffledSongs(shuffled); // Store the original shuffled order
    
            // Set current song index to 0 to start from the beginning of the shuffled queue
            setCurrentSongIndex(0);
        } else {
            // Switching to normal mode
            // Find the index of the current song in the normal queue
            // This ensures the currently playing song continues to play
            const newIndex = queue.findIndex(song => song.id === currentSong.id);
            setCurrentSongIndex(newIndex >= 0 ? newIndex : 0);
        }
    };
    const updateQueue = (newSongs) => {
        // Find the currently playing song
        const currentSong = isShuffle ? shuffledSongs[currentSongIndex] : queue[currentSongIndex];
    
        // Update the current song index if it has changed
        const newIndex = newSongs.findIndex(song => song.id === currentSong.id);
    
        if (isShuffle) {
            // Update the shuffledSongs state and keep it in sync
            const newShuffledSongs = newSongs.map(song => {
                return shuffledSongs.find(s => s.id === song.id);
            });
            setShuffledSongs(newShuffledSongs);
    
            // Find the index of the current song in the new shuffled queue
            const newShuffledIndex = newShuffledSongs.findIndex(song => song.id === currentSong.id);
    
            // Update the current song index to the new index in the shuffled queue
            if (newShuffledIndex !== currentSongIndex) {
                setCurrentSongIndex(newShuffledIndex);
            }
        } else {
            // If not in shuffle mode, just reorder the queue
            setQueue(newSongs);
    
            // Update the current song index if it has changed
            if (newIndex !== currentSongIndex) {
                setCurrentSongIndex(newIndex);
            }
        }
    
        // Update the original queue to reflect the new state after user modifications
        // This ensures that after all songs are played, the queue can be reset to the state preferred by the user
        //setOriginalQueue([...newSongs]);
    };
    

    const togglePlayPause = () => {
        if (isPlaying) {
            pauseAudio();
        } else {
            playAudio();
        }
        setIsPlaying(!isPlaying);
    };
    const volumeIcon = (
            <svg className = "vol" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <path d="M533.6 32.5C598.5 85.2 640 165.8 640 256s-41.5 170.7-106.4 223.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C557.5 398.2 592 331.2 592 256s-34.5-142.2-88.7-186.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM473.1 107c43.2 35.2 70.9 88.9 70.9 149s-27.7 113.8-70.9 149c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C475.3 341.3 496 301.1 496 256s-20.7-85.3-53.2-111.8c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zm-60.5 74.5C434.1 199.1 448 225.9 448 256s-13.9 56.9-35.4 74.5c-10.3 8.4-25.4 6.8-33.8-3.5s-6.8-25.4 3.5-33.8C393.1 284.4 400 271 400 256s-6.9-28.4-17.7-37.3c-10.3-8.4-11.8-23.5-3.5-33.8s23.5-11.8 33.8-3.5zM301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3z"/>
                </svg>
    );

    const muteIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path d="M301.1 34.8C312.6 40 320 51.4 320 64V448c0 12.6-7.4 24-18.9 29.2s-25 3.1-34.4-5.3L131.8 352H64c-35.3 0-64-28.7-64-64V224c0-35.3 28.7-64 64-64h67.8L266.7 40.1c9.4-8.4 22.9-10.4 34.4-5.3zM425 167l55 55 55-55c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-55 55 55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-55-55-55 55c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l55-55-55-55c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"/>
                </svg>
            </svg>
    );

    const pauseIcon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/>
        </svg>
    );
    
    const playIcon = (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 384 512">
            <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/>
        </svg>
    );
    const prevIcon = (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">
            <path d="M459.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4L288 214.3V256v41.7L459.5 440.6zM256 352V256 128 96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160C4.2 237.5 0 246.5 0 256s4.2 18.5 11.5 24.6l192 160c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V352z"/>
        </svg>
    );

    const nextIcon = (
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512">
            <path d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4L224 214.3V256v41.7L52.5 440.6zM256 352V256 128 96c0-12.4 7.2-23.7 18.4-29s24.5-3.6 34.1 4.4l192 160c7.3 6.1 11.5 15.1 11.5 24.6s-4.2 18.5-11.5 24.6l-192 160c-9.5 7.9-22.8 9.7-34.1 4.4s-18.4-16.6-18.4-29V352z"/>
        </svg>
    );
    const Shuffle = (
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
            <path d="M403.8 34.4c12-5 25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160H352c-10.1 0-19.6 4.7-25.6 12.8L284 229.3 244 176l31.2-41.6C293.3 110.2 321.8 96 352 96h32V64c0-12.9 7.8-24.6 19.8-29.6zM164 282.7L204 336l-31.2 41.6C154.7 401.8 126.2 416 96 416H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c10.1 0 19.6-4.7 25.6-12.8L164 282.7zm274.6 188c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V416H352c-30.2 0-58.7-14.2-76.8-38.4L121.6 172.8c-6-8.1-15.5-12.8-25.6-12.8H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H96c30.2 0 58.7 14.2 76.8 38.4L326.4 339.2c6 8.1 15.5 12.8 25.6 12.8h32V320c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l64 64c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-64 64z"/>
        </svg>
    );

    const queueIcon = (
        <div className='QueueIcon'>
            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 256 256" >
                <path d="M166.15,153.65c-11.8,0-21.4,8-21.4,17.85s9.6,17.85,21.4,17.85a24.3,24.3,0,0,0,11.77-3c6-3.32,9.63-8.88,9.63-14.89V107.81a4.08,4.08,0,0,1,4.08-4.08h25a2.59,2.59,0,0,0,2.59-2.59v-11a2.59,2.59,0,0,0-2.59-2.59H186.3a5.41,5.41,0,0,0-5.41,5.4v65.27l-3-1.65A24.19,24.19,0,0,0,166.15,153.65Z"/>
                <path d="M39,60.86a8.27,8.27,0,0,0-8.25,8.26v6.43A8.26,8.26,0,0,0,39,83.8H159.55a8.26,8.26,0,0,0,8.24-8.25V69.12a8.26,8.26,0,0,0-8.24-8.26Z"/>
                <path d="M39,151a8.26,8.26,0,0,0-8.25,8.25v6.44A8.26,8.26,0,0,0,39,173.89h74.54a8.26,8.26,0,0,0,8.25-8.24v-6.44a8.26,8.26,0,0,0-8.25-8.25Z"/>
                <path d="M39,105.91a8.26,8.26,0,0,0-8.25,8.26v6.43A8.25,8.25,0,0,0,39,128.84H159.55a8.25,8.25,0,0,0,8.24-8.24v-6.43a8.26,8.26,0,0,0-8.24-8.26Z"/>
            </svg>
        </div>
    )
    const currentSong = useMemo(() => {
        return isShuffle ? shuffledSongs[currentSongIndex] : queue[currentSongIndex];
    }, [currentSongIndex, isShuffle, shuffledSongs, queue]);
    

    useEffect(() => {
        const nextSongUrl = currentSong.url;
        if (audioRef.current.src !== nextSongUrl) {
            audioRef.current.src = nextSongUrl;
        }
    }, [currentSong.url]); // Only depend on currentSong.url// Depend on currentSong.url and isPlaying to prevent unnecessary updates
    //console.log(currentSong)
    //console.log('Current Song:', currentSong);
    //console.log('Queue Songs Before Filter:', queue);
    //console.log("Queue after filer", filteredQueue)
    //console.log(currentSong)
return (
        <div className="audio-player">
            <button onClick={toggleQueue} className="queue-toggle-btn">{queueIcon}</button>
        
            {isQueueVisible ? (
                // Show the queue if isQueueVisible is true
                enableScroll(),
                <Queue currentSong = {currentSong} key={queue.toString()} queueSongs={isShuffle ? shuffledSongs : queue} updateQueue={updateQueue} />
            ) : (
                // Otherwise, show the player controls
                <>
                    <div className="song-info">
                        <img src={currentSong.imageUrl} alt={currentSong.name} className="song-image" />
                        <div className="song-details">
                            <div className="song-name">{currentSong.name}</div>
                            <div className="song-artist">{currentSong.artist}</div>
                        </div>
                    </div>
                    <div className="audio-controls">
                        <button onClick={toggleShuffle} className={`shuffle-btn ${isShuffle ? 'shuffle-btn-active' : ''}`}>
                            {Shuffle}
                        </button>
                        <button onClick={playPreviousSong} className="skip-btn">
                            {prevIcon}
                        </button>
                        <button onClick={togglePlayPause} className="control-button">
                            {isPlaying ? pauseIcon : playIcon}
                        </button>
                        <button onClick={playNextSong} className="skip-btn">
                            {nextIcon}
                        </button>
                        <div className="volume-wrapper">
                            <button onClick={toggleMute} className="muteButton">
                                {isMuted ? muteIcon : volumeIcon}
                            </button>
                            <input
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                                value={isMuted ? 0 : volume}
                                onChange={onVolumeChange}
                                className="volume-slider"
                            />
                        </div>
                    </div>
                    <div className="progress-container">
                        <input
                            type="range"
                            min="0"
                            max="100"
                            value={progress}
                            onChange={updateProgress}
                            className="progress-bar"
                        />
                    </div>
                    <div className="song-time">{formatTime(currentTime)}</div>
                </>
            )}

            <audio 
                ref={audioRef} 
                src={queue[currentSongIndex].url} // Use the current song URL
                key={currentSongIndex}
                autoPlay={isPlaying}
                onEnded={playNextSong}
            />
        </div>
    );
};

export default AudioPlayer;